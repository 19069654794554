import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row gap-x-4 mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_claim_detail_card = _resolveComponent("claim-detail-card")!
  const _component_claim_rejected_reason_card = _resolveComponent("claim-rejected-reason-card")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_Title, { label: "Klaim" }),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_claim_detail_card, {
        class: 
          `${
            _ctx.checkActiveSectionDashboard('rejected_reason') ? 'w-9/12' : 'w-full'
          }`
        
      }, null, 8, ["class"]),
      (_ctx.checkActiveSectionDashboard('rejected_reason'))
        ? (_openBlock(), _createBlock(_component_claim_rejected_reason_card, {
            key: 0,
            class: "w-3/12"
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}