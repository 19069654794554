
import { Vue, Options } from "vue-class-component";
import CardDashboardClient from "@/app/ui/views/dashboard/component/client/component/card-dashboard-client.vue";
import DetailStatusCardDashboardClient from "@/app/ui/views/dashboard/component/client/component/detail-status-card-dashboard-client.vue";
import {
  formatPrice,
  IS_SENDER_ACCOUNT
} from "@/app/infrastructures/misc/Utils";
import ProgressBar from "@/app/ui/components/progress-bar/index.vue";
import DonutChart from "@/app/ui/components/donut-chart/index.vue";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import { CLAIM } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    DetailStatusCardDashboardClient,
    CardDashboardClient,
    ProgressBar,
    DonutChart
  }
})
export default class ClaimDetailCard extends Vue {
  mounted() {
    this.fetchClaimDataCorporateDashboard();
  }
  async fetchClaimDataCorporateDashboard() {
    this.isLoading = true;
    const response = await DashboardController.getCorporateDashboardDeliveryData(
      {
        path: "/claim/total",
        sttCategory: "",
        cache: true
      }
    );
    this.claimPerStatus[0].value = response.totalClaimSubmitted;
    this.claimPerStatus[1].value = response.totalClaimApproved;
    this.claimPerStatus[2].value = response.totalClaimRejected;
    this.claimPerStatus[3].value = response.totalClaimCompleted;
    this.claimMbg = response.totalClaimMbg;
    this.totalClaim = response.totalClaim;
    this.isLoading = false;
  }

  isLoading = true;
  totalClaim = 0;
  claimPerStatus: Array<any> = [
    {
      title: "Diajukan",
      type: "on_request",
      value: 0,
      loading: false,
      color: "#FFCB01",
      ableToClick: this.ableToViewDetail
    },
    {
      title: "Disetujui",
      type: "on_approved",
      value: 0,
      loading: false,
      color: "#7ACB90",
      ableToClick: this.ableToViewDetail
    },
    {
      title: "Ditolak",
      type: "on_rejected",
      value: 0,
      loading: false,
      color: "#F05C72",
      ableToClick: this.ableToViewDetail
    },
    {
      title: "Selesai",
      type: "on_done",
      value: 0,
      loading: false,
      color: "#8E78DB",
      ableToClick: this.ableToViewDetail
    }
  ];

  // claim mbg
  claimMbg = 0;
  get formattedClaimMbg() {
    return formatPrice(this.claimMbg);
  }
  get chartData() {
    return this.claimPerStatus.map((item: any) => {
      return item.value;
    });
  }
  get chartColor() {
    return this.claimPerStatus.map((item: any) => {
      return item.color;
    });
  }
  valueProgressBar(val: number, maxValue: number) {
    return (val / maxValue) * 100;
  }

  get ableToViewDetail() {
    if (IS_SENDER_ACCOUNT) return true;
    return checkRolePermission(CLAIM.ENABLE);
  }
  onClickDetail(dashboardInfo: string) {
    if (!this.ableToViewDetail) return;
    DashboardController.openNewPageFromDashboardCorporate(dashboardInfo);
  }
}
